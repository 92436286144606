let orgin = location.host;
let url = {
  "pcEn": "showen.zhanshangxiu.com",
  "pc": "show.zhanshangxiu.com",
  "mobileEn": "men.zhanshangxiu.com",
  "mobile": "m2.zhanshangxiu.com",
  "admin": "console.zhanshangxiu.com",
};
//  import url from "@/utils/url";
// baseUrl: `https://${this.url.mobile}`,

const opt = {
  cacshowonline: {
    "pcEn": "www.cacshowonline.com",
    "pc": "cn.cacshowonline.com",
    "mobileEn": "mobile.cacshowonline.com",
    "mobile": "m.cacshowonline.com",
    "admin": "onlinelogin.cacshowonline.com",
  },
  cac: {
    "pcEn": "cac.cacshowonline.com",
    "pc": "caccn.cacshowonline.com",
    "mobileEn": "caccnm.cacshowonline.com",
    "mobile": "cacm.cacshowonline.com",
    "admin": "caclogin.cacshowonline.com,"
  },
  fshow: {
    "pcEn": "fshow.cacshowonline.com",
    "pc": "fshowcn.cacshowonline.com",
    "mobileEn": "fshowcnm.cacshowonline.com",
    "mobile": "fshowm.cacshowonline.com",
    "admin": "fshowlogin.cacshowonline.com",
  },
  cace: {
    "pcEn": "cace.cacshowonline.com",
    "pc": "cacecn.cacshowonline.com",
    "mobileEn": "cacecnm.cacshowonline.com",
    "mobile": "cacem.cacshowonline.com",
    "admin": "cacelogin.cacshowonline.com",
  },
  seed: {
    "pcEn": "seed.cacshowonline.com",
    "pc": "seedcn.cacshowonline.com",
    "mobileEn": "seedcnm.cacshowonline.com",
    "mobile": "seedm.cacshowonline.com",
    "admin": "seedlogin.cacshowonline.com",
  },
  caa: {
    "pcEn": "caa.cacshowonline.com",
    "pc": "caacn.cacshowonline.com",
    "mobileEn": "caacnm.cacshowonline.com",
    "mobile": "caam.cacshowonline.com",
    "admin": "caalogin.cacshowonline.com",
  },
  test: {
    "pcEn": "t-showen.zhanshangxiu.com",
    "pc": "t-show.zhanshangxiu.com",
    "mobileEn": "t-men.zhanshangxiu.com",
    "mobile": "t-m.zhanshangxiu.com",
    "admin": "t-console.zhanshangxiu.com",
  }
}

switch (orgin) {
  case opt.cacshowonline.pcEn:
    url = opt.cacshowonline;
    break;
  case opt.cac.pcEn:
    url = opt.cac;
    break;
  case opt.fshow.pcEn:
    url = opt.fshow;
    break;
  case opt.cace.pcEn:
    url = opt.cace;
    break;
  case opt.seed.pcEn:
    url = opt.seed;
    break;
  case opt.caa.pcEn:
    url = opt.caa;
    break;
  case opt.test.pcEn:
    url = opt.test
    break;
}
export default {
  "pcEn": url.pcEn,
  "pc": url.pc,
  "mobileEn": url.mobileEn,
  "mobile": url.mobile,
  "admin": url.admin,
};