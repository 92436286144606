<template>
  <div class="page">
    <VTcPlayer ref="tcPlayer" :options="options" @load="onLoad" @play="onPlay" />
  </div>
</template>
<!--Polyfill-->
<!--[if lt IE 9]>
<script src="https://imgcache.qq.com/open/qcloud/video/vcplayer/libs/es5-shim.js"></script>
<script src="https://imgcache.qq.com/open/qcloud/video/vcplayer/libs/es5-sham.js"></script>
<![endif]-->
<script>
import { VTcPlayer } from 'v-tcplayer';
export default {
  name: "live-video",
  props: {
    url: {
      type: String,
      default: ""
    },
    cover: {
      type: String,
      default:
        "https://img.zhanshangxiu.com/showonline/file/202010/20201030/209a518f609b466db8ae37dbae41d0ed.jpg"
    },
    liveStatus: {
      type: Number,
      default: 0
    },
    showBg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
        options:{
          width: 878,
          height: 496,
          autoplay: true,
          m3u8: "",
        },
    };
  },
  components: {
    VTcPlayer,
  },
  computed: {
    player () {
      // tcPlayer实例
      return this.$refs.tcPlayer.vTcPlayer
    },
  },
  created(){
    this.options = {
      width: 878,
      height: 496,
      autoplay: true,
      m3u8:this.url,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.onPlay();
    })
  },
  methods: {
    play () {
      this.player.play()
    },
    getCurrentTime () {
      this.player.currentTime()
    },
    onLoad(){},
    onPlay(){},
  }
};
</script>

<style lang="scss">
html {
  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.page {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  object-fit: fill;
  
}
.video-player {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.video_box {
  object-fit: fill !important;
  background: #131825;
  z-index: 88;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.icon_play {
  position: absolute;
  top: 44%;
  right: 0;
  left: 0;
  bottom: auto;
  margin: auto;
  z-index: 90;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.play,
.platStart {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 89;
  width: 100%;
  height: 100%;
}
.play{
  // object-fit: cover;
}
.vcp-player{
  height: 4.3rem;
}
video{
  height: 4.3rem;
}
</style>