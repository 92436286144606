<template>
    <div>
        <div class="sche_content" v-if="DateList.length>0">
            <div class="steps_bar" :style="'bottom: 20px'"></div>
            <template v-for="(item,index) in DateList">
                <!-- <div class="sche_item" :key="'sche-1-' + index">
                <div class="step">{{index+1}}</div>
                <div class="info" style="width: 80%">
                    <p class="date">{{item[0].startAt | moment('YYYY/MM/DD')}}</p>
                </div>
                </div> -->
                <div
                class="sche_item2"
                v-for="(it, idx) in item"
                :key="'sche-child-' + index + idx"
                >
                <div class="step"></div>
                <div class="info" style="width: 80%">
                    <p class="date">
                    {{it.startAt | moment('YYYY/MM/DD HH:mm')}} ~ {{it.endAt | moment('YYYY/MM/DD HH:mm')}}
                    <span
                        class="schedule_tag red"
                        id="waa"
                        v-if="it.state==1"
                    >ing</span>
                    <span class="schedule_tag" v-if="it.state==2">{{ $t('lang.end')}}</span>
                    </p>
                    <p class="sche_type" :class="it.state==1 ? 'ing' : ''">
                    {{it.title || it.content}}
                    </p>
                </div>
                </div>
            </template>
        </div>
        <!-- <div class="view-more">{{$t('lang.look_More')}}</div> -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props:['actData'],
    data(){
        return{
          status: 0,
          DateList:[],
        }
    },
    computed:{
      ...mapGetters({
        exhibitionId:'getExhibitionId',
      }),
      actId(){
        return this.$route.query.id;
      },
    },
    created(){
      console.log(this.actData,'哈哈哈哈哈哈');
      if(this.actData.exhibitorId||!this.actData.isExhibitionActivity){  // 展商日程 
        this.getscheList();
      } else { //展会日程
        this.getExscheList();
      }
    },
    methods: {
      ...mapActions({
        graphqlPost:'graphqlPostByZXS',
      }),
      async getscheList(){
        let query = `
            query activitySchedules($activityId: Guid!){
                activitySchedules(activityId: $activityId){
                    activityId
                    content
                    createAt
                    endTime
                    date
                    id
                    isDeleted
                    startTime
                    title
                    updateAt
                }
              }`;
        let opt = {
            query: query,
            variables:{
              activityId:this.actId,
            }
        };
        let data = await this.graphqlPost(opt);
        if(data.data.activitySchedules){
          let List = JSON.parse(JSON.stringify(data.data.activitySchedules));
          var obj={},newArr=[];
          List.sort(function(a,b) {
              return Date.parse(b.startTime.replace(/-/g,"/"))-Date.parse(a.startTime.replace(/-/g,"/"));
          });
          List.forEach((item,suffix)=>{
              // let startAt = item.date.slice(0,11) + item.startTime.slice(11,19);
              item.startAt = item.startTime.replace(/-/g, "/");
              // let endAt = item.date.slice(0,11) + item.endTime.slice(11,19);
              item.endAt = item.endTime.replace(/-/g, "/");
              item.state = this.compareDate(item.startAt, item.endAt)
              if(!obj[item.startAt.substring(0, 11)]){
                  var arr=[];
                  arr.push(item);
                  newArr.push(arr);
                  obj[item.startAt.substring(0, 11)]=item;
              }else{
                  newArr.forEach((value,index)=>{
                      if(value[0].startAt.substring(0, 11)==item.startAt.substring(0, 11)){
                          value.push(item)
                      }
                  })
              }
          })
          newArr.forEach((e=>{
              e.sort((a,b)=>{
                return Date.parse(a.startTime.replace(/-/g,"/"))-Date.parse(b.startTime.replace(/-/g,"/"));
              })
            }))
          this.DateList = newArr.reverse();
          console.log(this.DateList,'this.DateList')
          if(this.DateList.length){
            this.$emit('finishInit',true)
          } else {
            this.$emit('finishInit',false)
          }
        }else {
          if(this.DateList.length){
            this.$emit('finishInit',true)
          } else {
            this.$emit('finishInit',false)
          }
        }
      },
      async getExscheList(){
          let query = ` 
            query exhibitionSchedules($exhibitionId: Guid!){
              exhibitionSchedules(exhibitionId: $exhibitionId){
                  createAt
                  date
                  endTime
                  id
                  isDeleted
                  startTime
                  theme
                  updateAt
              }
            }`;
          let opt = {
            query: query,
            variables:{
              exhibitionId:this.exhibitionId,
            }
          };
          let data = await this.graphqlPost(opt);
          if(data.data.exhibitionSchedules){
            let List = JSON.parse(JSON.stringify(data.data.exhibitionSchedules));
            var obj={},newArr=[];
            List.sort(function(a,b) {
                return Date.parse(b.startTime.replace(/-/g,"/"))-Date.parse(a.startTime.replace(/-/g,"/"));
            });
            List.forEach((item,suffix)=>{
                // let startAt = item.date.slice(0,11) + item.startTime.slice(11,19);
                item.startAt = item.startTime.replace(/\-/g, "/");
                // let endAt = item.date.slice(0,11) + item.endTime.slice(11,19);
                item.endAt = item.endTime.replace(/\-/g, "/");
                item.title = item.theme;
                item.state = this.compareDate(item.startAt, item.endAt)
                if(!obj[item.startAt.substring(0, 11)]){
                    var arr=[];
                    arr.push(item);
                    newArr.push(arr);
                    obj[item.startAt.substring(0, 11)]=item;
                }else{
                    newArr.forEach((value,index)=>{
                        if(value[0].startAt.substring(0, 11)==item.startAt.substring(0, 11)){
                            value.push(item)
                        }
                    })
                }
            })
            newArr.forEach((e=>{
              e.sort((a,b)=>{
                return Date.parse(a.startTime.replace(/-/g,"/"))-Date.parse(b.startTime.replace(/-/g,"/"));
              })
            }))
            this.DateList = newArr.reverse();
            if(this.DateList.length){
              this.$emit('finishInit',true)
            } else {
              this.$emit('finishInit',false)
            }
            console.log(this.DateList,'this.DateList')
          } else {
            if(this.DateList.length){
              this.$emit('finishInit',true)
            } else {
              this.$emit('finishInit',false)
            }
          }
      },
      // 比较时间
      compareDate(statedate, enddate) {
        const stateTime = new Date(statedate).getTime();
        const Time = new Date().getTime();
        const endTime = new Date(enddate).getTime();
        if (stateTime < Time && Time < endTime) {
          return 1; //进行中
        } else if (endTime < Time) {
          return 2; //已结束
        } else {
          return 3; //未开始
        }
      },
    },
}
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.sche_content {
  padding: 0 10px;
  position: relative;
  .steps_bar {
    width: 3px;
    position: absolute;
    left: 23px;
    z-index: 0;
    top: 0px;
    bottom: 0;
    background: #ff4550;
  }
  .sche_item {
    display: flex;
    position: relative;
    z-index: 1;
    &.end {
      .sche_type {
        color: #999;
      }
    }
    .step {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      background: linear-gradient(to left, #ff416c, #ff4b2b);
      color: #fff;
      font-size: 14px;
      text-align: center;
    }
    .info {
      font-size: 15px;
      color: #ff5c00;
      display: flex;
      align-items: center;
    }
  }
  .sche_item2 {
    display: flex;
    margin-top: 10px;
    position: relative;
    z-index: 1;
    &.end {
      .sche_type {
        color: #999;
      }
    }
    .step {
      margin: 0 22px 0 8px;
      width: 12px;
      height: 12px;
      line-height: 12px;
      border-radius: 50%;
      background: linear-gradient(to left, #ff416c, #ff4b2b);
      color: #fff;
      font-size: 14px;
      text-align: center;
    }
    .date {
      line-height: 14px;
      height: 14px;
      font-size: 14px;
      margin-bottom: 6px;
      font-size: 12px;
      color: #999;
    }
    .sche_type {
      line-height: 1.5;
      margin-bottom: 14px;
      font-size: 12px;
      color: #999;
      span {
        color: #666;
        margin-left: 15px;
      }
    }
    .ing{
      color: #333;
    }
  }
  .schedule_tag{
    font-size: 12px;
    vertical-align: middle;
    padding: 2px 6px;
    border-radius: 3px;
    display: inline-block;
    background: #e6e6e6;
    color: #666;
    margin-left: 10px;
  }
  .red{
    background-color: #ff4550;
    color: #fff;
  }
}
.view-more{
  text-align: center;
  cursor: pointer;
  color: #999;
}
</style>